import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';

export const query = graphql`
  {
    markdownRemark(frontmatter: { page_id: { eq: "policy-page" } }) {
      html
    }
  }
`;

interface Props {
  data: {
    markdownRemark: { html: string };
  };
}

const pravilnik: React.FC<Props> = ({ data }) => (
  <>
    <SEO title="Pravilnik" />
    {/* <PortableText value={data.settings.rules} /> // WAIT FOR https://github.com/sanity-io/gatsby-source-sanity/issues/160 to be resolved */}
    <Container dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
  </>
);

const Container = styled.div`
  margin: 0 auto;
  max-width: 60rem;

  margin-bottom: 50vh;

  h2,
  h3,
  h4 {
    color: var(--crna);
    border-bottom: 1px solid var(--crna);
    margin: 1.6rem 0 0.4rem 0;
  }

  li {
    font-size: 1rem;
    margin: 0.6rem 0;
  }

  ul,
  ol {
    margin-left: 1.4rem;
  }
`;

export default pravilnik;
